/* ====== SIDENAV START ======= */

.dl_sidenav {
  width: 150px;
  height: 100%;
  position: fixed;
  color: #fff;
  background-color: #202d4f;
}

.sidenav_img_div {
  text-align: center;
}

.sidenav_menu_div {
  margin-top: 1em;
}

.sidenav_menu_div ul {
  position: absolute;
  top: 35%;
  left: 0;
  width: 100%;
}

.linkStyle {
  color: #fff;
  padding: 0.75em 0.5em;
  font-size: 12px;
  display: block;
}

.linkStyle:hover {
  text-decoration: none;
  color: #fff;
  background: #203864;
}

.linkStyle_select {

  background: #203864;
  
  color: #fff;
  padding: 0.75em 0.5em;
  font-size: 12px;
  display: block;
}

.is-active {
  background: #203864;
}

/* ====== SIDENAV END ======== */
