table {
    color: "#fff";
    font-size: 11px;
}
thead {
        background: "#6CA0EC";
        text-align: "center";
        font-size: 20px;
}
td {
    border-collapse: collapse;
    border: solid 1px #dee2e6;
    text-align: center;
    color: white;
    font-size: 11px;
}