* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
body {
  background-color: #283964;
  /*font-family:"Montserrat", sans-serif;*/
  /*font-family: "Roboto";*/
  font-family: "Open Sans";
 
}
.wrapper {
  display: flex;
  position: relative;
}
.main_content_wrapper {
  width: 100%;
  margin-left: 160px;
}
table {
  width: 99.9%;
}
table, th, td {
  border-collapse: collapse;
  border: solid 1px #dee2e6;
  text-align: center;
  color: white;
}
.text_color_back {
  color: black;
}

.text_color_white {
  color: white;
}

button {
  vertical-align: middle;
}
.first_div {
  color: #dee2e6;
  background-color: #12192c;
  border-radius: 0.2em;
  
  padding-top: 5px;
  padding-right: 3px;
  padding-bottom: 3px;
  padding-left: 3px;

  margin-top: 0em;
  margin-bottom: 0em;
  margin-right: 1em;
  margin-left: 0em;
  
}

.second_div {
  width: 99.5%;
  margin: 0 0 0.1em 0.5em;
  font-size: small;

  padding-bottom: 5px;
}

.selection_div {
  width: 100%;
  vertical-align: middle;
  padding-top: 3px;
  padding-right: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
}

.exchange_selection_div {

  color: #dee2e6;
  width: 100%;
  vertical-align: middle;

  margin-top: 0em;
  margin-bottom: 1em;
  margin-right: 0em;
  margin-left: 0em;
}

.div_aline_center {
  display: flex;
  justify-content: center;
}


.div_flex {
  display: flex;
}

.div_aline_left {
  display: flex;
  text-align: left;
}

.btn_save {
  border: 1px solid #00B0F0;
  background-color: white;
  color: black;
  padding: 2px 2px;
  font-size: 10px;
  cursor: pointer;
}

.save {
  border-color: #00B0F0;
  color: black;
}

.save:hover {
  background-color: #00B0F0;
  color: white;;
}

.btn_m {
  height: 25px;
  width: 100%;
}

.btn_l{
  width: 100%;
}


.btn_center {
  margin: 0 auto;
  padding: 0;
  display: inline-block;
  line-height: 40px;
  text-align: center;
}


.btn-space-5 {
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.table_header {
  background-color: #4472C4;
}

.mlc {
  background-color: #203664;
}
.p2v {
  background-color: #114736;
}
.tbt {
  background-color: #391755;
}

.inbput_m {
  height: 25px;
  width: 25px;
  background-color: white;
}


.table_none_border {
  border-right:none;
  border-left:none;
  border-top:none;
  border-bottom:none;
}


/* CJ 추가~~~~~~ */
/* CJ 추가~~~~~~ */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider w*/
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0; 
  bottom: 0;
  background-color: #cccccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  margin-top: 10px;
}

.progress-bar-inner {
  width: 100%;
  height: 20px;
  background-color: #3A5E99;
  -webkit-animation: loading 2s infinite;
          animation: loading 2s infinite;
}

@-webkit-keyframes loading {
  0% {
      width: 0;
  }
  100% {
      width: 100%;
  }
}

@keyframes loading {
  0% {
      width: 0;
  }
  100% {
      width: 100%;
  }
}
/* ====== HEADER START======== */
.dl_header {
  padding: 20px;
  color: #fff;
  background-color: #2c3e6d;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dl_header span {
  font-weight: 600;
}

.logout_btn {
  background: transparent;
  border: rgb(0, 207, 0) 1px solid;
  padding: 0.25em 0.8em;
  color: rgb(0, 207, 0);
  transition: 0.3s;
}

.logout_btn:hover {
  background: rgb(0, 207, 0);
  color: #fff;
}


.header_div {
  display: flex;
  justify-content: left;
  margin-top: 1em;
  margin-bottom: 0em;
  margin-left:  1em;
}

.header_sub_div_left {
  width: 30%;
  display: flex;
  justify-content: left;
  margin-top: 0em;
  margin-bottom: 0em;
  margin-right: 1em;
  margin-left: 0em;
}

.header_sub_div_right {
  width: 70%;
  display: flex;
  justify-content: right;
  margin-top: 0em;
  margin-bottom: 0em;
  margin-right: 1.5em;
  margin-left: 0em;
}


.header_tile{
  color: white;
}

.header_btn_logout {
  display: flex;
  justify-content: right;
  font-size: small;
  height: 30px;
  margin-top: 1em;
  margin-bottom: 1em;
}

/* ======= HEADER END ========= */

/* ====== SIDENAV START ======= */

.dl_sidenav {
  width: 150px;
  height: 100%;
  position: fixed;
  color: #fff;
  background-color: #202d4f;
}

.sidenav_img_div {
  text-align: center;
}

.sidenav_menu_div {
  margin-top: 1em;
}

.sidenav_menu_div ul {
  position: absolute;
  top: 35%;
  left: 0;
  width: 100%;
}

.linkStyle {
  color: #fff;
  padding: 0.75em 0.5em;
  font-size: 12px;
  display: block;
}

.linkStyle:hover {
  text-decoration: none;
  color: #fff;
  background: #203864;
}

.linkStyle_select {

  background: #203864;
  
  color: #fff;
  padding: 0.75em 0.5em;
  font-size: 12px;
  display: block;
}

.is-active {
  background: #203864;
}

/* ====== SIDENAV END ======== */

/* ====== SIDENAV SUB START ======= */

.subLinkDiv {
  background: #203864;
}

.subLinkStyle {

  background: #12192c;
  color: #fff;
  display: block;
  font-size: 11px;
  padding: 0.6em 0 0.6em 3.5em;
}

.subLinkStyle:hover {
  color: #00B0F0;
  text-decoration: none;
  background: rgb(16, 17, 51);
}

.subLinkStyle_select{

  background: #12192c;
  color: #fff;
  display: block;
  font-size: 11px;
  padding: 0.6em 0 0.6em 3.5em;
}

/* ====== SIDENAV SUB END ======== */


/* ==== DASHBOARD START ======= */
.gpe_wgTabA {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #dadada;
    *zoom: 1;
  }
  /* ==== DASHBOARD END ==========*/
  
  .Dasbord_Button {
    
    display: inline-block;
    text-align: center;
    align-items: flex-start;

    margin-top: 0.1em;
    margin-right: 1em;

    padding:0.2em 2em;
}

.Year_Button {
   
  display: inline-block;
  text-align: center;
  align-items: flex-start;

  margin-top: 0.1em;
  margin-right: 1em;

  padding:0.2em 2em;
}
.Month_Button {
    
  display: inline-block;
  text-align: center;
  align-items: flex-start;

  margin-top: 0.1em;
  margin-right: 0.1em;

  padding:0.1em 1em;
}


.dashbod_div_sub_cell {
    color: #dee2e6;
    background-color: #12192c;
    border-radius: 0.2em;
    
    padding-top: 5px;
    padding-right: 3px;
    padding-bottom: 3px;
    padding-left: 3px;
  
    margin-top: 0em;
    margin-bottom: 0em;
    margin-right: 1em;
    margin-left: 0em;
    
  }
h1 {
  color: #fff;
}

.login_bg {
  background: #202d4f;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_content_div {
  text-align: center;
}

.login_input_div input {
  border-top: none;
  border-left: none;
  border-right: none;
  background: transparent;
  border-bottom: 2px solid #fff;
  color: rgb(0, 202, 0);
  outline: none;
}
.login_input_div {
  position: relative;
  margin-top: 1em;
}
.floating-label {
  position: absolute;
  pointer-events: none;
  top: 0px;
  left: 55px;
  font-size: 18px;
  transition: 0.2s ease all;
}

.login:focus ~ .floating-label,
.login:not(:focus):valid ~ .floating-label {
  top: -20px;
  left: 55px;
  font-size: 12px;
  opacity: 1;
}

.submit {
  margin-top: 1.5em;
}

.modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
}
.modal button {
    outline: none;
    cursor: pointer;
    border: 0;
}
.modal > section {
    width: 90%;
    max-width: 450px;
    margin:0 auto;
    border-radius: .3rem;
    background-color: #fff;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    -webkit-animation: modal-show .3s;
            animation: modal-show .3s;
    overflow: hidden;
}
.modal > section > header {
    position: relative;
    padding: 16px 64px 16px 16px;
    background-color: #f1f1f1;
    font-weight: 700;
}
.modal > section > header button {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    font-size: 21px;
    font-weight: 700;
    text-align: center;
    color: #999;
    background-color: transparent;
}
.modal > section > main {
    padding: 16px;
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
}
.modal > section > footer {
    padding: 12px 16px;
    text-align: right;
}
.modal > section > footer button {
    padding: 6px 12px;
    color: #fff;
    background-color: #6c757d;
    border-radius: 5px;
    font-size: 13px;
    margin-left: 5px;
}
.modal.openModal {
    display: flex;
    align-items: center;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    -webkit-animation: modal-bg-show .3s;
            animation: modal-bg-show .3s;
}
@-webkit-keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}
@keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}
@-webkit-keyframes modal-bg-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes modal-bg-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* ==== DASHBOARD START ======= */
.gpe_wgTabA {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #dadada;
    *zoom: 1;
  }
  /* ==== DASHBOARD END ==========*/
  

.dashbod_div_sub_cell {
    color: #dee2e6;
    background-color: #12192c;
    border-radius: 0.2em;
    
    padding-top: 5px;
    padding-right: 3px;
    padding-bottom: 3px;
    padding-left: 3px;
  
    margin-top: 0em;
    margin-bottom: 0em;
    margin-right: 1em;
    margin-left: 0em;
    
  }
/* ==== DASHBOARD START ======= */
.gpe_wgTabA {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #dadada;
  *zoom: 1;
}
/* ==== DASHBOARD END ==========*/

/* ==== ARBITRAGE START ======= */
.arbitrage_contents {
  background-color: #283964;
  color: #fff;
  height: 100vh;
  padding: 1em;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.btnClass {
  background-color: rgb(77, 95, 95) ;
}

.btnClass.clicked {
  background-color: rgb(24, 159, 226);
}


/* ==== ARBITRAGE END ==========*/


/* CJ 추가~~~~~~ */
/* CJ 추가~~~~~~ */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

.read-only {
  background-color: rgba(219, 225, 221, 0.1);
  color: rgba(220, 243, 9, 0.4);
  border-color: rgba(0, 0, 0, .2);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.div_arbit {
  color: #dee2e6;
  background-color: #283964;
  border-radius: 1em;
  margin: 1em;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  justify-content: 'center';
}

.second_div_arbit {
  width: 99.5%;
  margin: 0 0 0.8em 0.5em;
  font-size: small;
}


.second_div_arbit_detail {
  width: 99.5%;
  margin: 0 0 0.8em 0.5em;
  font-size: small;
  padding-top: 0.1em;
  padding-bottom: 0.3em;
}



.main {
  color: #dee2e6;
}
th{
  font-size: 10px;
}
td{
  font-size: 10px;
}
th, td {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  padding-left: 0.3em;
  padding-right: 0.3em;
}
button{
  cursor: pointer;
}
table {
    color: "#fff";
    font-size: 11px;
}
thead {
        background: "#6CA0EC";
        text-align: "center";
        font-size: 20px;
}
td {
    border-collapse: collapse;
    border: solid 1px #dee2e6;
    text-align: center;
    color: white;
    font-size: 11px;
}
th{
  font-size: 9px;
}
td{
  font-size: 10px;
}
th, td {
  padding-left: 0.3em;
  padding-right: 0.3em;
}
.logTr:hover {
  cursor: pointer;
  background-color:blue;
}
.paginationBtns {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .paginationActive {
    font-weight: 100;
  }
  .paginationBtns li {
    margin: 0.4em;
  }
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  a:not([href]):not([class]) {
    color: #fff;
    text-decoration: none;
  }
  
.main {
  color: #dee2e6;
}
th{
  font-size: 10px;
}
td{
  font-size: 10px;
}
th, td {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  padding-left: 0.3em;
  padding-right: 0.3em;
}
button{
  cursor: pointer;
}
table {
    color: "#fff";
    font-size: 11px;
}
thead {
        background: "#6CA0EC";
        text-align: "center";
        font-size: 20px;
}
td {
    border-collapse: collapse;
    border: solid 1px #dee2e6;
    text-align: center;
    font-size: 11px;
}

.detail_th{
    font-size: 9px;
  }
  .detail_td{
    font-size: 10px;
    color: rgb(4, 24, 97);
  }
  .detail_th, .detail_td {
    padding-left: 0.3em;
    padding-right: 0.3em;
  }
.paginationBtns {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
}
.modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
}
.modal button {
    outline: none;
    cursor: pointer;
    border: 0;
}
.modal > section {
    width: 100%;
    max-width: 1200px;
    margin:0 auto;
    border-radius: .3rem;
    background-color: #fff;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    -webkit-animation: modal-show .3s;
            animation: modal-show .3s;
    overflow: hidden;
}
.modal > section > header {
    position: relative;
    padding: 16px 64px 16px 16px;
    background-color: #f1f1f1;
    font-weight: 700;
}
.modal > section > header button {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    font-size: 21px;
    font-weight: 700;
    text-align: center;
    color: #999;
    background-color: transparent;
}
.modal > section > main {
    padding: 16px;
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
}
.modal > section > footer {
    padding: 12px 16px;
    text-align: right;
}
.modal > section > footer button {
    padding: 6px 12px;
    color: #fff;
    background-color: #6c757d;
    border-radius: 5px;
    font-size: 13px;
    margin-left: 5px;
}
.modal.openModal {
    display: flex;
    align-items: center;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    -webkit-animation: modal-bg-show .3s;
            animation: modal-bg-show .3s;
}
@-webkit-keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}
@keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}
@-webkit-keyframes modal-bg-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes modal-bg-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
th{
  font-size: 9px;
}
td{
  font-size: 10px;
}
th, td {
  padding-left: 0.3em;
  padding-right: 0.3em;
}
.logTr:hover {
  cursor: pointer;
  background-color:blue;
}
.paginationBtns {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .paginationActive {
    font-weight: 100;
  }
  .paginationBtns li {
    margin: 0.4em;
  }
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  a:not([href]):not([class]) {
    color: #fff;
    text-decoration: none;
  }
  
th{
  font-size: 9px;
}
td{
  font-size: 10px;
}
th, td {
  padding-left: 0.3em;
  padding-right: 0.3em;
}
.logTr:hover {
  cursor: pointer;
  background-color:blue;
}
.paginationBtns {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .paginationActive {
    font-weight: 100;
  }
  .paginationBtns li {
    margin: 0.4em;
  }
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  a:not([href]):not([class]) {
    color: #fff;
    text-decoration: none;
  }
  
.main {
  color: #dee2e6;
}
th{
  font-size: 10px;
}
td{
  font-size: 10px;
}
th, td {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  padding-left: 0.3em;
  padding-right: 0.3em;
}
button{
  cursor: pointer;
}
table {
    color: "#fff";
    font-size: 11px;
}
thead {
        background: "#6CA0EC";
        text-align: "center";
        font-size: 20px;
}
td {
    border-collapse: collapse;
    border: solid 1px #dee2e6;
    text-align: center;
    font-size: 11px;
}

.detail_th{
    font-size: 9px;
  }
  .detail_td{
    font-size: 10px;
    color: rgb(4, 24, 97);
  }
  .detail_th, .detail_td {
    padding-left: 0.3em;
    padding-right: 0.3em;
  }
.paginationBtns {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
}
th{
  font-size: 9px;
}
td{
}
th, td {
  padding-left: 0.3em;
  padding-right: 0.3em;
}
.logTr:hover {
  cursor: pointer;
  background-color:blue;
}
.paginationBtns {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .paginationActive {
    background-color: rgb(64, 87, 159);
    font-weight: 100;
  }
  .paginationBtns li {
    margin: 0.4em;
  }
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  a:not([href]):not([class]) {
    color: #fff;
    text-decoration: none;
  }
  
th{
  font-size: 9px;
}
td{
  font-size: 10px;
}
th, td {
  padding-left: 0.3em;
  padding-right: 0.3em;
}
.logTr:hover {
  cursor: pointer;
  background-color:blue;
}
.paginationBtns {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .paginationActive {
    font-weight: 100;
  }
  .paginationBtns li {
    margin: 0.4em;
  }
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  a:not([href]):not([class]) {
    color: #fff;
    text-decoration: none;
  }
  
table {
    color: "#fff";
    font-size: 11px;
}
thead {
        background: "#6CA0EC";
        text-align: "center";
        font-size: 20px;
}
td {
    border-collapse: collapse;
    border: solid 1px #dee2e6;
    text-align: center;
    color: white;
    font-size: 11px;
}
th{
  font-size: 9px;
}
td{
  font-size: 10px;
}
th, td {
  padding-left: 0.3em;
  padding-right: 0.3em;
}
.logTr:hover {
  cursor: pointer;
  background-color:blue;
}
.paginationBtns {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .paginationActive {
    font-weight: 100;
  }
  .paginationBtns li {
    margin: 0.4em;
  }
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  a:not([href]):not([class]) {
    color: #fff;
    text-decoration: none;
  }
  
th, td {
  padding-left: 0.3em;
  padding-right: 0.3em;
}
.paginationBtns {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .paginationActive {
    font-weight: 100;
  }
  .paginationBtns li {
    margin: 0.4em;
  }
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  a:not([href]):not([class]) {
    color: #fff;
    text-decoration: none;
  }
  
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* ==== ARBITRAGE START ======= */
.arbitrage_contents {
  background-color: #283964;
  color: #fff;
  height: 100vh;
  padding: 1em;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.btnClass {
  background-color: rgb(77, 95, 95) ;
}

.btnClass.clicked {
  background-color: rgb(24, 159, 226);
}


/* ==== ARBITRAGE END ==========*/


/* CJ 추가~~~~~~ */
/* CJ 추가~~~~~~ */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

.read-only {
  background-color: rgba(219, 225, 221, 0.1);
  color: rgba(220, 243, 9, 0.4);
  border-color: rgba(0, 0, 0, .2);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.div_arbit {
  color: #dee2e6;
  background-color: #283964;
  border-radius: 1em;
  margin: 1em;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  justify-content: 'center';
}

.second_div_arbit {
  width: 99.5%;
  margin: 0 0 0.8em 0.5em;
  font-size: small;
}


.second_div_arbit_detail {
  width: 99.5%;
  margin: 0 0 0.8em 0.5em;
  font-size: small;
  padding-top: 0.1em;
  padding-bottom: 0.3em;
}



input[type="checkbox"] {
    -webkit-appearance: none;
    position: relative;
    width: 16px;
    height: 16px;
    cursor: pointer;
    outline: none !important;
    border: 1px solid #eeeeee;
    border-radius: 2px;
    background: #fbfbfb;
}

input[type="checkbox"]::before {
    content: "\2713";
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    -webkit-transform: scale(0) translate(-50%, -50%);
            transform: scale(0) translate(-50%, -50%);
    line-height: 1;
}

input[type="checkbox"]:hover {
    border-color: rgba(170, 170, 170, 0.5);
}

input[type="checkbox"]:checked {
    background-color: #ff8955;
    border-color: rgba(255, 255, 255, 0.3);
    color: white;
}

input[type="checkbox"]:checked::before {
    border-radius: 2px;
    -webkit-transform: scale(1) translate(-50%, -50%);
            transform: scale(1) translate(-50%, -50%)
}


html,
body,
#root,
.App {
  margin: 0;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.chart-container {
  flex: 1 1;
}

.modalchart {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
}
.modalchart button {
    outline: none;
    cursor: pointer;
    border: 0;
}
.modalchart > section {
    width: 90%;
    max-width: 450px;
    margin:0 auto;
    border-radius: .3rem;
    background-color: #fff;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    -webkit-animation: modalchart-show .3s;
            animation: modalchart-show .3s;
    overflow: hidden;
}
.modalchart > section > header {
    position: relative;
    padding: 16px 64px 16px 16px;
    background-color: #f1f1f1;
    font-weight: 700;
}
.modalchart > section > header button {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    font-size: 21px;
    font-weight: 700;
    text-align: center;
    color: #999;
    background-color: transparent;
}
.modalchart > section > main {
    padding: 16px;
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
}
.modalchart > section > footer {
    padding: 12px 16px;
    text-align: right;
}
.modalchart > section > footer button {
    padding: 6px 12px;
    color: #fff;
    background-color: #6c757d;
    border-radius: 5px;
    font-size: 13px;
    margin-left: 5px;
}
.modalchart.openModal {
   
    align-items: center;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    -webkit-animation: modal-bg-show .3s;
            animation: modal-bg-show .3s;
}
@-webkit-keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}
@keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}
@-webkit-keyframes modal-bg-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes modal-bg-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
