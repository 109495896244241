
/* ==== DASHBOARD START ======= */
.gpe_wgTabA {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #dadada;
    *zoom: 1;
  }
  /* ==== DASHBOARD END ==========*/
  

.dashbod_div_sub_cell {
    color: #dee2e6;
    background-color: #12192c;
    border-radius: 0.2em;
    
    padding-top: 5px;
    padding-right: 3px;
    padding-bottom: 3px;
    padding-left: 3px;
  
    margin-top: 0em;
    margin-bottom: 0em;
    margin-right: 1em;
    margin-left: 0em;
    
  }