.main {
  color: #dee2e6;
}
th{
  font-size: 10px;
}
td{
  font-size: 10px;
}
th, td {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  padding-left: 0.3em;
  padding-right: 0.3em;
}
button{
  cursor: pointer;
}