/* ==== ARBITRAGE START ======= */
.arbitrage_contents {
  background-color: #283964;
  color: #fff;
  height: 100vh;
  padding: 1em;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.btnClass {
  background-color: rgb(77, 95, 95) ;
}

.btnClass.clicked {
  background-color: rgb(24, 159, 226);
}


/* ==== ARBITRAGE END ==========*/


/* CJ 추가~~~~~~ */
/* CJ 추가~~~~~~ */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.read-only {
  background-color: rgba(219, 225, 221, 0.1);
  color: rgba(220, 243, 9, 0.4);
  border-color: rgba(0, 0, 0, .2);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.div_arbit {
  color: #dee2e6;
  background-color: #283964;
  border-radius: 1em;
  margin: 1em;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  justify-content: 'center';
}

.second_div_arbit {
  width: 99.5%;
  margin: 0 0 0.8em 0.5em;
  font-size: small;
}


.second_div_arbit_detail {
  width: 99.5%;
  margin: 0 0 0.8em 0.5em;
  font-size: small;
  padding-top: 0.1em;
  padding-bottom: 0.3em;
}



input[type="checkbox"] {
    -webkit-appearance: none;
    position: relative;
    width: 16px;
    height: 16px;
    cursor: pointer;
    outline: none !important;
    border: 1px solid #eeeeee;
    border-radius: 2px;
    background: #fbfbfb;
}

input[type="checkbox"]::before {
    content: "\2713";
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    transform: scale(0) translate(-50%, -50%);
    line-height: 1;
}

input[type="checkbox"]:hover {
    border-color: rgba(170, 170, 170, 0.5);
}

input[type="checkbox"]:checked {
    background-color: #ff8955;
    border-color: rgba(255, 255, 255, 0.3);
    color: white;
}

input[type="checkbox"]:checked::before {
    border-radius: 2px;
    transform: scale(1) translate(-50%, -50%)
}

