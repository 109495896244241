/* ==== DASHBOARD START ======= */
.gpe_wgTabA {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #dadada;
  *zoom: 1;
}
/* ==== DASHBOARD END ==========*/
