* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
body {
  background-color: #283964;
  /*font-family:"Montserrat", sans-serif;*/
  /*font-family: "Roboto";*/
  font-family: "Open Sans";
 
}
.wrapper {
  display: flex;
  position: relative;
}
.main_content_wrapper {
  width: 100%;
  margin-left: 160px;
}
table {
  width: 99.9%;
}
table, th, td {
  border-collapse: collapse;
  border: solid 1px #dee2e6;
  text-align: center;
  color: white;
}
.text_color_back {
  color: black;
}

.text_color_white {
  color: white;
}

button {
  vertical-align: middle;
}
.first_div {
  color: #dee2e6;
  background-color: #12192c;
  border-radius: 0.2em;
  
  padding-top: 5px;
  padding-right: 3px;
  padding-bottom: 3px;
  padding-left: 3px;

  margin-top: 0em;
  margin-bottom: 0em;
  margin-right: 1em;
  margin-left: 0em;
  
}

.second_div {
  width: 99.5%;
  margin: 0 0 0.1em 0.5em;
  font-size: small;

  padding-bottom: 5px;
}

.selection_div {
  width: 100%;
  vertical-align: middle;
  padding-top: 3px;
  padding-right: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
}

.exchange_selection_div {

  color: #dee2e6;
  width: 100%;
  vertical-align: middle;

  margin-top: 0em;
  margin-bottom: 1em;
  margin-right: 0em;
  margin-left: 0em;
}

.div_aline_center {
  display: flex;
  justify-content: center;
}


.div_flex {
  display: flex;
}

.div_aline_left {
  display: flex;
  text-align: left;
}

.btn_save {
  border: 1px solid #00B0F0;
  background-color: white;
  color: black;
  padding: 2px 2px;
  font-size: 10px;
  cursor: pointer;
}

.save {
  border-color: #00B0F0;
  color: black;
}

.save:hover {
  background-color: #00B0F0;
  color: white;;
}

.btn_m {
  height: 25px;
  width: 100%;
}

.btn_l{
  width: 100%;
}


.btn_center {
  margin: 0 auto;
  padding: 0;
  display: inline-block;
  line-height: 40px;
  text-align: center;
}


.btn-space-5 {
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.table_header {
  background-color: #4472C4;
}

.mlc {
  background-color: #203664;
}
.p2v {
  background-color: #114736;
}
.tbt {
  background-color: #391755;
}

.inbput_m {
  height: 25px;
  width: 25px;
  background-color: white;
}


.table_none_border {
  border-right:none;
  border-left:none;
  border-top:none;
  border-bottom:none;
}


/* CJ 추가~~~~~~ */
/* CJ 추가~~~~~~ */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider w*/
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0; 
  bottom: 0;
  background-color: #cccccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  margin-top: 10px;
}

.progress-bar-inner {
  width: 100%;
  height: 20px;
  background-color: #3A5E99;
  animation: loading 2s infinite;
}

@keyframes loading {
  0% {
      width: 0;
  }
  100% {
      width: 100%;
  }
}