h1 {
  color: #fff;
}

.login_bg {
  background: #202d4f;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_content_div {
  text-align: center;
}

.login_input_div input {
  border-top: none;
  border-left: none;
  border-right: none;
  background: transparent;
  border-bottom: 2px solid #fff;
  color: rgb(0, 202, 0);
  outline: none;
}
.login_input_div {
  position: relative;
  margin-top: 1em;
}
.floating-label {
  position: absolute;
  pointer-events: none;
  top: 0px;
  left: 55px;
  font-size: 18px;
  transition: 0.2s ease all;
}

.login:focus ~ .floating-label,
.login:not(:focus):valid ~ .floating-label {
  top: -20px;
  left: 55px;
  font-size: 12px;
  opacity: 1;
}

.submit {
  margin-top: 1.5em;
}
