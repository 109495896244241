th{
  font-size: 9px;
}
td{
  font-size: 10px;
}
th, td {
  padding-left: 0.3em;
  padding-right: 0.3em;
}
.logTr:hover {
  cursor: pointer;
  background-color:blue;
}
.paginationBtns {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .paginationActive {
    font-weight: 100;
  }
  .paginationBtns li {
    margin: 0.4em;
  }
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  a:not([href]):not([class]) {
    color: #fff;
    text-decoration: none;
  }
  