/* ====== HEADER START======== */
.dl_header {
  padding: 20px;
  color: #fff;
  background-color: #2c3e6d;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dl_header span {
  font-weight: 600;
}

.logout_btn {
  background: transparent;
  border: rgb(0, 207, 0) 1px solid;
  padding: 0.25em 0.8em;
  color: rgb(0, 207, 0);
  transition: 0.3s;
}

.logout_btn:hover {
  background: rgb(0, 207, 0);
  color: #fff;
}


.header_div {
  display: flex;
  justify-content: left;
  margin-top: 1em;
  margin-bottom: 0em;
  margin-left:  1em;
}

.header_sub_div_left {
  width: 30%;
  display: flex;
  justify-content: left;
  margin-top: 0em;
  margin-bottom: 0em;
  margin-right: 1em;
  margin-left: 0em;
}

.header_sub_div_right {
  width: 70%;
  display: flex;
  justify-content: right;
  margin-top: 0em;
  margin-bottom: 0em;
  margin-right: 1.5em;
  margin-left: 0em;
}


.header_tile{
  color: white;
}

.header_btn_logout {
  display: flex;
  justify-content: right;
  font-size: small;
  height: 30px;
  margin-top: 1em;
  margin-bottom: 1em;
}

/* ======= HEADER END ========= */
